<template>
  <v-card class="mb-7" elevation="0" outlined tile>
    <v-card-text class="pa-4">
      <div v-if="elementVisible">
        <vue-apex-charts
          type="bar"
          height="400"
          :options="dashboardOptions.length > 0 ? dashboardOptions[0] : {}"
          :series="series"
        ></vue-apex-charts>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
export default {
  name: 'CompromisosXArea',
  props: {
    datos: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    series: [
      {
        name: 'Compromisos',
        data: []
      }
    ],
    chartOptions: {
      grid: {
        show: true,
        borderColor: 'rgba(0, 0, 0, .3)',
        strokeDashArray: 3,
        padding: {
          left: 0,
          right: 0,
          bottom: 0
        }
      },
      chart: {
        toolbar: {
          show: true
        },
        width: '100%',
        height: 350
      },
      colors: ['#1D4B23'],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '48%',
          endingShape: 'flat'
        }
      },
      title: {
        text: 'CANTIDAD DE COMPROMISOS POR ÁREA',
        align: 'center',
        margin: 5,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '18px',
          fontWeight: 'normal',
          fontFamily: 'Roboto, Helvetica, Arial',
          color: 'rgba(0, 0, 0, 0.8)'
        }
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '14px',
        fontFamily: 'Roboto, Helvetica, Arial',
        markers: {
          width: 14,
          height: 14,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        },
        labels: {
          colors: '#424242',
          useSeriesColors: false
        },
        itemMargin: {
          horizontal: 15,
          vertical: 5
        },
        onItemHover: {
          highlightDataSeries: true
        },
        onItemClick: {
          toggleDataSeries: true
        }
      },
      dataLabels: {
        enabled: true
      },
      xaxis: {
        categories: [],
        labels: {
          trim: true,
          style: {
            cssClass: 'text-muted fill-color'
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            cssClass: 'text-muted fill-color'
          }
        }
      },
      tooltip: {
        theme: 'dark'
      }
    },
    elementVisible: false,
    dashboardOptions: []
  }),
  components: {
    VueApexCharts
  },
  watch: {
    datos(val) {
      let areas = [];
      let areasaux = [];
      let data = [];
      if (val.areas?.length > 0) {
        val.areas.forEach((el) => {
          areas.push(el.descripcion);
          areasaux.push(el.descripcion.split(' '));
          data.push(0);
        });
        this.dashboardOptions.push({
          ...this.chartOptions,
          ...{
            xaxis: {
              type: 'category',
              categories: areasaux,
              labels: {
                trim: true,
                style: {
                  fontSize: '10px',
                  fontWeight: 'bolder',
                  cssClass: 'text-muted fill-color'
                }
              }
            }
          }
        });
        if (val.acuerdossubareas.length > 0) {
          val.acuerdossubareas.forEach((el) => {
            areas.forEach((elem, index) => {
              if (el.descripcion.trim().toUpperCase() == elem.trim().toUpperCase()) {
                data[index] = data[index] + parseInt(el.totalno) + parseInt(el.totalporvencer) + parseInt(el.totalsi);
              }
            });
          });
        }
        this.series = [{ name: 'Compromisos', data: data }];
        this.elementVisible = true;
      } else {
        this.series = [{ name: 'Compromisos', data: [] }];
      }
    }
  },
  created() {}
};
</script>
